/**
 * API and Render url defined
 */
export const RENDER_URL = {
    HOME_URL: '/dashboard',
    LOGIN_URL: "/login",
    REGISTER_URL: "/register",
    CREATE_ARTICLE: "/newarticle",
    ADD_MENU: "/addmenu",
    ARTICLE_LIST: "/articlelist",
    VIEW_ARTICLE: "/view-article",
    FORGOT_PASSWORD: "/forgot-password",
    EDIT_ARTICLE: "/edit-article",
    CREATE_MAGAZINE: "/crete-magazine",
    MAGAZINE_LIST: "/magazine-list",
    RATINGREPORT: "/rating-report",
    SITE_VISTIORS_REPORT: "/site-visitors-report",
    REVIEW_REPORT: "/review-reports",
    USER_ARTICLES: "/user-articles",
    ADD_MANAGEMENT: "/manage-adds",
    USER_MAGAZINE_LIST: "/magazines",
    READ_MAGAZINE: "/read-magazine",
    RESET_PASSWORD: "/reset-password",
    ABOUT_US: "/about-us",
    CONTACT_US: "/contact-us",
    EDITORIAL_BOARD: "/editorial-board",
    MEDIA_KIT: "/media-kit",
    PARTENER_WITHUS: "/partners-withus",
    EDITORIAL_TERMS: "/editorial-terms",
    PRIVACY: "/privacy",
    POLICY: "/policy-comments",
    UPLOAD_VIDEO: "/video-upload",
    VIDEO_LIST: "/video-list",
    CREATE_OPINION: "/create-opinion",
    VIDEOS: "/videos",
    OPINIONS: "/opinions",
    VIEW_OPINION: "/view-opinion",
    REPORTS: "/reports",
    VIEW_REPORTS: "/view-reports",
    PRODUCTS: "/products",
    SERVICES: "/services",
    CREATE_PRODUCT_SERVICES: "/create-product-services",
    MANAGE_PRODUCT_SERVICES: "/manage-product-services",
    CREATE_USER: "/create-user",
    USER_LIST: "/user-list",
    OPINION_LIST: "/opinion-list",
    EDIT_OPINION : "/edit-opinion",
    DATA_PROTECTION:"/data-protection",
    GUEST_DASHBOARD :"/guest-dashboard",
    GUEST_MAGAZINES :"/guest-magazines",
    GUEST_ARTICLES :"/guest-articles",
    UNSUBSCRIBE : "/unsubcribe",
    USER_DETAILS:"/user-details-report",
    EVENTS : "/events",
    USER_EVENTS : "/user-events",
    CONFERENCE : "/conference2021"
   
};


export const API_URL = {
    REGISTRATION: "/user/register",
    LOGIN: "/user/login",
    GET_ARTICLES: "/admin/article/getArticlesByStatus",
    CREATE_ARTICLE: "/admin/article/createArticle",
    CREATE_ARTICLE_TYPE: "admin/article/createArticleType",
    GET_ARTICLE_TYPES: "/admin/article/getArticleTypes",
    GET_ARTICLE_AUTHORS: "/admin/article/getArticleAuthors",
    ARTICLE_ACTION: "/admin/article/updateArticleAction",
    FORGOT_PASSWORD: "/forgot/forgotpassword",
    GET_ARTICLE_BY_ID: "/admin/article/getArticleByID",
    UPDATE_ARTICLE: "/admin/article/updateArticle",
    CRETE_MAGAZINE: "/admin/magazine/createMagazine",
    GET_MAGAZINES: "/admin/magazine/getMagazineByStatus",
    ON_MAGAZINE_ACTION: "/admin/magazine/updateMagazineStatus",
    GET_RATINGS: "/admin/article/getArticleRatingReport",
    GET_REVIEW_RATINGS: "/admin/article/getArticlesReviewDetails",
    GET_SITE_VISITORS_REPORT: "/user/getVistorReport",
    GET_ARTICLES_BY_TYPE: "admin/article/getArticlesByType",
    GET_LATEST_ARTICLES: "/admin/article/getLastestArticle",
    CRETE_ADD: "/admin/adv/createAdvs",
    GET_ADDS: "/admin/adv/getAllAds",
    DELETE_ADV: "/admin/adv/deleteAdvs",
    UPDATE_ADD: "/admin/adv/updateAdvs",
    GET_MAGAZINE_BY_ID: "/showbook",
    REVIEW_ARTICLE: "/admin/article/insertReviewArticle",
    CONTACT_US: "/user/contactus",
    MEDIA_KIT: "/user/contactformedikit",
    CHANGE_PASSWORD: "/user/changePassword",
    PARTENER_WITHUS: "/user/contactPartner",
    FACEBOOK_SIGNUP: "/user/registerfacebook",
    GOOGLE_SIGNUP: "/user/registergoogleuser",
    VIDEO_UPLOAD: "/admin/video/uploadVideo",
    GET_VIDEOS: "/admin/video/getVideoList",
    DELETE_VIDEO: "/admin/video/deleteVideo",
    CREATE_OPINION: "/admin/reportopinion/createReportOpinion",
    GET_OPINIONS: "/admin/reportopinion/getAllReportOpinion",
    GET_OPINION_BY_ID: "/admin/reportopinion/getReportOpinionByID",
    LINKEDIN_SIGNUP: "/user/linkedinCodeAuth",
    CREATE_PRODUCT_SERVICES: "/admin/productservice/createProductService",
    GET_PRODUCT_SERVICES: "/admin/productservice/getProductService",
    DELETE_PRODUCT_SERVICES: "/admin/productservice/deleteProductService",
    UPDATE_PRODUCT_SERVICES: "admin/productservice/updateProductService",
    GET_ADMINS: "/user/getAdmins",
    DELETE_USER: "/user/deleteAdmin",
    DELETE_OPINION: "/admin/reportopinion/deleteReportOpinion",
    EDIT_OPINION : "/admin/reportopinion/updateReportOpinion",
    SEARCH_ARTICLE:"/admin/article/searchArticle",
    GET_INIT_ARTICLES : "/admin/article/getLandingPageArticles",
    SUBSCRIBE_NOW : "/user/subscribe",
    GET_USER_DETAILS_REPORT : "/user/getAllUserDetails",
    GET_EVENTS : "/admin/event/getEvents",    
    CREATE_EVENTS : "/admin/event/createEvents",
    DELETE_EVENT : "/admin/event/deleteEvents",
    EDIT_EVENTS : "/admin/event/modifyEvents",
    CONFERENCE_REGISTER: "/conference/conferenceregister",
}
