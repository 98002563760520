import {
    LOGIN_SUCCESS,
    LOGOUT
} from "../Utils/Constants";
import { ManageLocalStorage } from "../Services/Localstorage"

let user = ManageLocalStorage.get("token") ? ManageLocalStorage.get("token") : null
export const loginInitialState = {
    user: user,
    isLoggedIn: ManageLocalStorage.get("token")
        ? true
        : false,
    isAdmin: ManageLocalStorage.get("isAdmin")==="N"?false:true
};

export const LoginReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return loginInitialState
    }
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload.emailID,
                isAdmin: action.payload.isAdmin === "Y"

            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                isAdmin: true
            };
        default:
            return state;
    }
};
