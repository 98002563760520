import React from "react";
import {
    Route,
    Redirect
} from "react-router-dom";
import { GlobalContext } from "../Context/Context"
const RootRouteGuard = ({ xComponent: Component, ...xProps }) => {

    const context = React.useContext(GlobalContext)
    const loginData = context.login;

    return (
        <Route
            {...xProps}
            render={routeParams => {
                const pathName = routeParams.match.path;
                if (loginData.isLoggedIn) {
                    if (pathName === '/login') {
                        return <Redirect to="/dashboard" />;
                    }
                    return <Component {...routeParams} key={routeParams.match.url} />;
                }
                if (
                    pathName === "/unsubcribe" ||
                    pathName === "/about-us" ||
                    pathName === "/contact-us" ||
                    pathName === "/editorial-board" ||
                    pathName === "/media-kit" ||
                    pathName === "/partners-withus" ||
                    pathName === "/editorial-terms" ||
                    pathName === "/privacy" ||
                    pathName === "/policy-comments" ||
                    pathName === '/guest-dashboard' ||
                    pathName === '/guest-articles' ||
                    pathName === '/guest-magazines' ||
                    pathName === '/login' ||
                    pathName === '/guest-articles' ||
                    pathName === '/guest-magazines' ||
                    pathName === '/register' ||
                    pathName === '/forgot-password' ||
                    pathName === '/conference2021' ||
                    pathName === '/opinions' ||
                    pathName === '/videos' ||
                    pathName === '/reports' ||
                    pathName === '/services' ||
                    pathName === '/products' ||
                    pathName === '/user-events' ||
                    pathName === "/view-reports" ||
                    pathName === "/view-opinion" ||
                    pathName === "/reset-password") {
                    return <Component {...routeParams} key={routeParams.match.url} />;
                }
                return <Redirect to="/guest-dashboard" />;
            }}
        />
    );
};
export default RootRouteGuard