import {
    SET_TYPES,
    SET_LATEST_ARTICLES,
    SET_ADDS,
    START_LOADING,
    STOP_LOADING
} from "../Utils/Constants";
import { ManageLocalStorage } from "../Services/Localstorage"
export const commonInitialState = {
    articleTypes: ManageLocalStorage.get("types")
        ? JSON.parse(ManageLocalStorage.get("types"))
        : [],
    latestArticles: ManageLocalStorage.get("latest")
        ? JSON.parse(ManageLocalStorage.get("latest"))
        : [],
    adds: ManageLocalStorage.get("adds")
        ? JSON.parse(ManageLocalStorage.get("adds"))
        : [],
        isLoading : false,
        isGuestAdd : false

};

export const commonReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return commonInitialState
    }
    switch (action.type) {
        case SET_TYPES:
            return {
                ...state,
                articleTypes: action.payload

            };
        case SET_LATEST_ARTICLES:
            return {
                ...state,
                latestArticles: action.payload

            };
        case SET_ADDS:
            
            return {
                ...state,
                adds: action.payload,
                isGuestAdd : action.isGuestAdd?true:false

            };
            case START_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            }

        default:
            return state;
    }
};
