import React, { useEffect, useState } from "react";
import Routes from "./Core/Routes";
import ReactGA from 'react-ga4';
// import test from "../public/js/main"
import { setBaseUrl } from "../src/Services/HttpService"
import { history } from "../src/Core/Store"
import scriptLoader from 'react-async-script-loader'
const App = (props) => {
  React.useEffect(() => {
    // console.log = console.warn = console.error = () => {};
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    let init = {
      method: "GET",
      headers: headers,
    };

    setBaseUrl(process.env.REACT_APP_API_BASE_URL || "");

    // fetch("/appConfig.json", init)
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((obj) => {
    //     console.log(obj)
    //     setBaseUrl(obj.baseUrl);
    //   });
      ReactGA.initialize('G-H5NSG7T4X8');
  }, []);


  return (

    <div className="App h-100">
      <Routes history={history} />
    </div>
  );
};

export default scriptLoader(
  '../public/js/main'
)(App)