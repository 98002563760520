//routing files
import React, { lazy, Suspense,useEffect } from "react";
import { RENDER_URL } from "../Utils/Urls";
import ContextStore from "../Context/ContextStore"
import RootRouteGuard from "./RootRouteGuard"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import HelmetMetaData from "../Modules/General/HelmetComponent"
const DashBoardContainer = lazy(() => import("../Modules/DashBoard/DashboardContainer"));
const LoginContainer = lazy(() => import("../Modules/Login/LoginContainer"));
const WrapperComponent = lazy(() => import("../Modules/General/WrapperComponent"));
const RegistrationContainer = lazy(() => import("../Modules/Register/RegistrationContainer"))
const CreateArticleContainer = lazy(() => import("../Modules/Admin/Article/CreateArticle/CreateArticleContainer"))
const MenuItemContainer = lazy(() => import("../Modules/Admin/MenuItems/MenuItemContainer"))
const ArticleListContainer = lazy(() => import("../Modules/Admin/Article/ArticleList/ArticleListContainer"))
const ViewArticleContainer = lazy(() => import("../Modules/User/Article/ViewArticle/ViewArticleContainer"))
const ForgotPasswordContainer = lazy(() => import("../Modules/Forgot-Password/ForgotPasswordContainer"))
const EditArticleContainer = lazy(() => import("../Modules/Admin/Article/EditArticle/EditArticleContainer"))
const CreateMagazinContainer = lazy(() => import("../Modules/Admin/Magazine/CreateMagazine/CreateMagazinContainer"))
const MagazineListContainer = lazy(() => import("../Modules/Admin/Magazine/MagazineList/MagazineListContainer"))
const RatingReportsContaniner = lazy(() => import("../Modules/Admin/Reports/RatingReports/RatingReportsContainer"))
const SiteVistiortsReportContainer = lazy(() => import("../Modules/Admin/Reports/SiteVistiortsReport/SiteVistiortsReportContainer"))
const ReviewReportsContainer = lazy(() => import("../Modules/Admin/Reports/ReviewReports/ReviewReportsContainer"))
const ArticleListUser = lazy(() => import("../Modules/User/Article/ArticleList/ArticleListContainer"))
const AddManagementContainer = lazy(() => import("../Modules/Admin/Adverticements/AddManagementContainer"))
const UserMagazineListContainer = lazy(() => (import("../Modules/User/Magazine/MagazineList/MagazineListContainer")))
const ReadMagazineContainer = lazy(() => import("../Modules/User/Magazine/Read-Magazine/ReadMagazineContainer"))
const ResetPasswordContainer = lazy(() => import("../Modules/Forgot-Password/ResetPasswordContainer"))
const AboutUs = lazy(() => import("../Modules/General/AboutUs"))
const ContactUs = lazy(() => import("../Modules/General/ContactUs"))
const EditorialBoard = lazy(() => import("../Modules/General/EditorialBoard"))
const MediaKit = lazy(() => import("../Modules/General/MediaKit"))
const ParnterWithUs = lazy(() => import("../Modules/General/ParnterWithUs"))
const EditorialTerms = lazy(() => import("../Modules/General/EditorialTerms"))
const Privacy = lazy(() => import("../Modules/General/Privacy"))
const PolicyComments = lazy(() => import("../Modules/General/PolicyComments"))
const UploadVideoContainer = lazy(() => import("../Modules/Admin/Video/UploadVideo/UploadVideoContainer"))
const VideoListContainer = lazy(() => import("../Modules/Admin/Video/VideoList/VideoListContainer"))
const CreateOpinionContainer = lazy(() => import("../Modules/Admin/Opinion/CreateOpinion/CreateOpinionContainer"))
const VideoListUserContainer = lazy(() => import("../Modules/User/Video/VideoList/VideoListContainer"))
const OpinionContainer = lazy(() => import("../Modules/User/Opinion/OpinionList/OpinionContainer"))
const ViewOpinionContainer = lazy(() => import("../Modules/User/Opinion/ViewOpinion/ViewOpinionContainer"))
const ReportContainer = lazy(() => import("../Modules/User/Reports/ReportList/ReportContainer"))
const ViewReportContainer = lazy(() => import("../Modules/User/Reports/ViewReport/ViewReportContainer"))
const ProductListContainer=lazy(()=> import("../Modules/User/ProductsAndServices//ProductList/ProductListContainer"))
const ServiceListContainer = lazy(()=>import("../Modules/User/ProductsAndServices/ServicesList/ServiceListContainer"))
const CreateProductsServicesContainer=lazy(()=>import("../Modules/Admin/ProductServices/CreateProductsServices/CreateProductsServicesContainer"))
const ManageProductServiceContainer=lazy(()=>import("../Modules/Admin/ProductServices/ProductServiceList/ProductServiceListContainer"))
const CreateUserContainer=lazy(()=>import("../Modules/Admin/Users/CreateUser/CreateUserContainer"))
const UserListContainer=lazy(()=>import("../Modules/Admin/Users/UserList/UserListContainer"))
const OpinionListContainer =lazy(()=>import("../Modules/Admin/Opinion/OpinionList/OpinionListContainer"))
const EditOpinionContainer = lazy(()=>import("../Modules/Admin/Opinion/EditOpinion/EditOpinionContainer"))
const PersonalDataProtectionComponent=lazy(()=>import("../Modules/General/PersonalDataProtectionComponent"))
const GuestDashboardContainer=lazy(()=>import("../Modules/Guest/GuestDashboard/GuestDashboardContainer"))
const GuestMagazines=lazy(()=>import("../Modules/Guest/Magazine/MagazineList/MagazineListContainer"))
const GuestArticles=lazy(()=>import("../Modules/Guest/Articles/ArticleList/ArticleListContainer"))
const UnsubscribeComponent=lazy(()=>import("../Modules/General/UnsubscribeComponent"))
const UserDetailsReportContainer=lazy(()=>import("../Modules/Admin/Reports/UserDetailsReport/UserDetailsReportContainer"))
const EventManagementContainer=lazy(()=>import("../Modules/Admin/Events/EventManagementContainer"))
const EventsContainer=lazy(()=>import("../Modules/User/Events/EventsContainer"))
const ConferenceContainer=lazy(()=>import("../Modules/Conference2021/ConferenceContainer"))
const Routes = ({ history }) => {


    return (
        <Router basename="/">
            <Suspense fallback={<div className="displayNone"></div>}>

                <ContextStore>
                    <WrapperComponent history={history} >
                    <HelmetMetaData></HelmetMetaData>
                        <Switch>
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.HOME_URL}
                                xComponent={DashBoardContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.LOGIN_URL}
                                xComponent={LoginContainer} />


                            <RootRouteGuard
                                exact
                                path={RENDER_URL.REGISTER_URL}
                                xComponent={RegistrationContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.CREATE_ARTICLE}
                                xComponent={CreateArticleContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.ADD_MENU}
                                xComponent={MenuItemContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.ARTICLE_LIST}
                                xComponent={ArticleListContainer}
                            />
                            <RootRouteGuard
                                // exact
                                path={RENDER_URL.VIEW_ARTICLE}
                                xComponent={ViewArticleContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.FORGOT_PASSWORD}
                                xComponent={ForgotPasswordContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.EDIT_ARTICLE}
                                xComponent={EditArticleContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.CREATE_MAGAZINE}
                                xComponent={CreateMagazinContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.MAGAZINE_LIST}
                                xComponent={MagazineListContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.RATINGREPORT}
                                xComponent={RatingReportsContaniner}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.SITE_VISTIORS_REPORT}
                                xComponent={SiteVistiortsReportContainer}
                            />

                            <RootRouteGuard
                                exact
                                path={RENDER_URL.REVIEW_REPORT}
                                xComponent={ReviewReportsContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.USER_ARTICLES}
                                xComponent={ArticleListUser}
                            />

                            <RootRouteGuard
                                exact
                                path={RENDER_URL.ADD_MANAGEMENT}
                                xComponent={AddManagementContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.USER_MAGAZINE_LIST}
                                xComponent={UserMagazineListContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.READ_MAGAZINE}
                                xComponent={ReadMagazineContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.RESET_PASSWORD}
                                xComponent={ResetPasswordContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.ABOUT_US}
                                xComponent={AboutUs}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.CONTACT_US}
                                xComponent={ContactUs}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.EDITORIAL_BOARD}
                                xComponent={EditorialBoard}
                            />

                            <RootRouteGuard
                                exact
                                path={RENDER_URL.MEDIA_KIT}
                                xComponent={MediaKit}
                            />

                            <RootRouteGuard
                                exact
                                path={RENDER_URL.PARTENER_WITHUS}
                                xComponent={ParnterWithUs}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.EDITORIAL_TERMS}
                                xComponent={EditorialTerms}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.PRIVACY}
                                xComponent={Privacy}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.POLICY}
                                xComponent={PolicyComments}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.UPLOAD_VIDEO}
                                xComponent={UploadVideoContainer}
                            />

                            <RootRouteGuard
                                exact
                                path={RENDER_URL.VIDEO_LIST}
                                xComponent={VideoListContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.CREATE_OPINION}
                                xComponent={CreateOpinionContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.VIDEOS}
                                xComponent={VideoListUserContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.OPINIONS}
                                xComponent={OpinionContainer}
                            />

                            <RootRouteGuard
                                exact
                                path={RENDER_URL.VIEW_OPINION}
                                xComponent={ViewOpinionContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.REPORTS}
                                xComponent={ReportContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.VIEW_REPORTS}
                                xComponent={ViewReportContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.PRODUCTS}
                                xComponent={ProductListContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.SERVICES}
                                xComponent={ServiceListContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.CREATE_PRODUCT_SERVICES}
                                xComponent={CreateProductsServicesContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.MANAGE_PRODUCT_SERVICES}
                                xComponent={ManageProductServiceContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.CREATE_USER}
                                xComponent={CreateUserContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.USER_LIST}
                                xComponent={UserListContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.OPINION_LIST}
                                xComponent={OpinionListContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.EDIT_OPINION}
                                xComponent={EditOpinionContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.DATA_PROTECTION}
                                xComponent={PersonalDataProtectionComponent}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.GUEST_DASHBOARD}
                                xComponent={GuestDashboardContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.GUEST_MAGAZINES}
                                xComponent={GuestMagazines}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.GUEST_ARTICLES}
                                xComponent={GuestArticles}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.UNSUBSCRIBE}
                                xComponent={UnsubscribeComponent}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.USER_DETAILS}
                                xComponent={UserDetailsReportContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.EVENTS}
                                xComponent={EventManagementContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.USER_EVENTS}
                                xComponent={EventsContainer}
                            />
                            <RootRouteGuard
                                exact
                                path={RENDER_URL.CONFERENCE}
                                xComponent={ConferenceContainer}
                            />


                            <Route
                                path={"*"}
                                render={(props) => <Redirect to="/dashboard" {...props} />}
                            />
                        </Switch>
                    </WrapperComponent>
                </ContextStore>
            </Suspense>
        </Router>
    );
};

export default Routes;